import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {collapsibleOpen} from '../../animations/general.animation';

@Component({
    selector: 'app-collapsible-panel',
    templateUrl: './collapsible-panel.component.html',
    styleUrls: ['./collapsible-panel.component.scss'],
    animations: [collapsibleOpen]
})
export class CollapsiblePanelComponent implements OnInit {
    @Input() public panelTitle: string;
    @Input() public additionalTitle: string;
    @Input() public noPadding: boolean;
    @Input() public autopdf: boolean = false;
    @Input() public folded: boolean = false;
    @Input() public promotionIconManufacturer: boolean = false;
    @Input() public promotionIconSpecialChoice: boolean = false;
    @Output() onChangeFolded: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    public toggleFolded() {
        this.folded = !this.folded;
        this.onChangeFolded.emit(this.folded);
    }

    ngOnInit() {}

}
