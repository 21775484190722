<div class="promo-banner" *ngIf="article?.hasPromotion">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
         class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z"/>
    </svg>
</div>

<div class="col-3 image-col" lazy-load-images
     [ngClass]="{'image-col-small': articlesService.selectedSearchResultSize === 'small'}">
    <span class="description"
          *ngIf="articlesService.selectedSearchResultSize === 'small'"> {{ article.description | ellipsis:'25' }} </span>
    <br *ngIf="articlesService.selectedSearchResultSize === 'small'"/>
    <img class="img-fluid" [attr.src]="article.previewImageUrl"/>
</div>

<!-- big -->
<div class="col-2 padding-top" *ngIf="articlesService.selectedSearchResultSize === 'big'" style="margin-left: 25%;">
    <span class="description">{{ article.description }}</span>
    <span class="brand"> {{ article.brandName }} </span> <span class="article-no"> {{ article.ipc }} </span><br/>
    <span *ngIf="article.productName" class="product-name">{{ article.productName }}</span>
    <span class="gtin" *ngIf="article.ean">
        {{ article.ean }}
    </span>
    <span *ngIf="article.replacesArticle" class="font-weight-bold font-italic">
        {{ 'SPAREPARTS_VIEW.DETAILS_PANEL.REPLACES_ARTICLE' | translate }} <span
            class="text-nowrap">{{ article.replacesArticle }}</span>
    </span>
    <span *ngIf="article.isReplacedByArticle" class="font-weight-bold font-italic">
        {{ 'SPAREPARTS_VIEW.DETAILS_PANEL.IS_REPLACED_BY_ARTICLE' | translate }} <span
            class="text-nowrap">{{ article.isReplacedByArticle }}</span>
    </span>
</div>

<!-- big -->
<div class="col-5 padding-top" *ngIf="articlesService.selectedSearchResultSize === 'big'">
    <div class="article-attributes" *ngIf="article.criterias.length > 0">
        <div>
            <span *ngIf="article.exchangePart">{{ 'SPAREPARTS_VIEW.EXCHANGE_PARTS' | translate }}</span>
            <span *ngIf="article.exchangePart && article.exchangeValue > 0"> (<strong>{{ 'SPAREPARTS_VIEW.EXCHANGE_VALUE' | translate }} {{ article.exchangeValueFormatted }}</strong>)</span>
        </div>
        <div *ngFor="let criteria of article.criterias" class="attribute">
            <!-- criteria.id === 8 -->
            <ng-container *ngIf="criteria.id === '8'">
                <hr style="width: 20vw"/>
            </ng-container>

            <!-- criteria.id !== 8 -->
            <ng-container *ngIf="criteria.id !== '8'">
                <span class="name">{{ criteria.name }}</span>
                <span *ngIf="criteria.displayValue">:&nbsp;</span>
                <span class="value">{{ criteria.displayValue }}</span>
            </ng-container>
        </div>
    </div>

    <div *ngIf="article.searchInformation?.length > 0">
        <ng-container *ngFor="let info of article.searchInformation">
            <ng-container *ngIf="info.important">
                <span class="badge badge-pill badge-info">{{ info.text }}</span>
            </ng-container>
        </ng-container>
    </div>

    <div class="description"
         *ngIf="article.criterias.length === 0 && articlesService.selectedSearchResultSize === 'big'">
        {{ article.description }}
    </div>
</div>

<!-- normal -->
<div class="col-7 padding-top" *ngIf="articlesService.selectedSearchResultSize === 'normal'" style="margin-left: 25%;">
    <div>
        <span class="description"> {{ article.description }} </span><br/>
        <span class="brand"> {{ article.brandName }} </span>
        <span class="article-no"> {{ article.ipc }} </span>
        <span *ngIf="article.replacesArticle" class="font-weight-bold font-italic ml-4">
        {{ 'SPAREPARTS_VIEW.DETAILS_PANEL.REPLACES_ARTICLE' | translate }} <span
                class="text-nowrap">{{ article.replacesArticle }}</span>
        </span>
        <span *ngIf="article.isReplacedByArticle" class="font-weight-bold font-italic ml-4">
        {{ 'SPAREPARTS_VIEW.DETAILS_PANEL.IS_REPLACED_BY_ARTICLE' | translate }} <span
                class="text-nowrap">{{ article.isReplacedByArticle }}</span>
        </span>
        &nbsp;<br/>
        <span *ngIf="article.productName" class="product-name"> {{ article.productName }}</span>

    </div>
    <div>
        <span *ngIf="article.exchangePart">{{ 'SPAREPARTS_VIEW.EXCHANGE_PARTS' | translate }}</span>
        <span *ngIf="article.exchangePart && article.exchangeValue > 0"> (<strong>{{ 'SPAREPARTS_VIEW.EXCHANGE_VALUE' | translate }} {{ article.exchangeValueFormatted }}</strong>)</span>
    </div>
    <div class="article-attributes" [ngClass]="{ 'small' : articlesService.selectedSearchResultSize === 'normal' }"
         *ngIf="article?.criterias?.length > 0">
        <div *ngFor="let criteria of article.criterias; last as isLast" class="attribute">
            <!-- criteria.id === 8 -->
            <ng-container *ngIf="criteria.id === '8'">
                <hr style="width: 25vw"/>
            </ng-container>

            <!-- criteria.id !== 8 -->
            <ng-container *ngIf="criteria.id !== '8'">
                <span class="name">{{ criteria.name }}</span>
                <span *ngIf="criteria.displayValue">:&nbsp;</span>
                <span class="value">{{ criteria.displayValue }}</span><!--
			--><span *ngIf="articlesService.selectedSearchResultSize === 'normal' && !isLast">,&nbsp;</span>
            </ng-container>
        </div>
    </div>
    <div *ngIf="article.searchInformation?.length > 0">
        <ng-container *ngFor="let info of article.searchInformation">
            <ng-container *ngIf="info.important">
                <span class="badge badge-pill badge-info">{{ info.text }}</span>
            </ng-container>
        </ng-container>
    </div>
</div>

<!-- small -->
<div class="col-7 padding-top smallRow" *ngIf="articlesService.selectedSearchResultSize === 'small'"
     style="margin-left: 25%;">
    <div>
        <span class="brand"> {{ article.brandName }} </span>
        <span class="article-no"> {{ article.ipc }} </span>
        <span *ngIf="article.productName" class="product-name"> {{ article.productName }}</span>
        <span *ngIf="article.replacesArticle" class="font-weight-bold font-italic ml-4">
        {{ 'SPAREPARTS_VIEW.DETAILS_PANEL.REPLACES_ARTICLE' | translate }} <span
                class="text-nowrap">{{ article.replacesArticle }}</span>
        </span>
        <span *ngIf="article.isReplacedByArticle" class="font-weight-bold font-italic ml-4">
        {{ 'SPAREPARTS_VIEW.DETAILS_PANEL.IS_REPLACED_BY_ARTICLE' | translate }} <span
                class="text-nowrap">{{ article.isReplacedByArticle }}</span>
        </span>
    </div>
    <div>
        <span *ngIf="article.exchangePart">{{ 'SPAREPARTS_VIEW.EXCHANGE_PARTS' | translate }}</span>
        <span *ngIf="article.exchangePart && article.exchangeValue > 0"> (<strong>{{ 'SPAREPARTS_VIEW.EXCHANGE_VALUE' | translate }} {{ article.exchangeValueFormatted }}</strong>)</span>
    </div>
    <div class="article-attributes" [ngClass]="{ 'small' : articlesService.selectedSearchResultSize === 'small' }"
         *ngIf="article.criterias.length > 0">
        <div *ngFor="let criteria of article.criterias; last as isLast" class="attribute">
            <!-- criteria.id === 8 -->
            <ng-container *ngIf="criteria.id === '8'">
                <hr style="width: 25vw"/>
            </ng-container>

            <!-- criteria.id !== 8 -->
            <ng-container *ngIf="criteria.id !== '8'">
                <span class="name">{{ criteria.name }}</span>
                <span *ngIf="criteria.displayValue">: </span>
                <span class="value">{{ criteria.displayValue }}</span><!--
			--><span *ngIf="articlesService.selectedSearchResultSize === 'small' && !isLast">, </span>
            </ng-container>

        </div>
    </div>
    <div *ngIf="article.searchInformation?.length > 0">
        <ng-container *ngFor="let info of article.searchInformation">
            <ng-container *ngIf="info.important">
                <span class="badge badge-pill badge-info">{{ info.text }}</span>
            </ng-container>
        </ng-container>
    </div>
</div>

<div class="col-2 padding-top" [ngClass]="{'padding-small': articlesService.selectedSearchResultSize === 'small'}">
    <div class="price" [ngClass]="{'small': articlesService.selectedSearchResultSize === 'small'}"
         *ngIf="article.uvp > 0 && !article.pseudoArticle">
        {{ article.uvpFormatted }}<br/>
        <small *ngIf="!userService.sale && !(cartService?.cartCount?.calculationData)">{{ 'SPAREPARTS_VIEW.SEARCH_RESULTS.RECOMMENDED_PRICE' | translate }}</small>
    </div>
    <div class="quantity-in-cart" *ngIf="article?.quantityInCart > 0">
        <span class="mr-2">{{ article.quantityInCart }} x <i class="fa fa-shopping-cart"></i></span>
    </div>
</div>

<ng-container *ngIf="!article.pseudoArticle">
    <div class="col-3"></div>
    <div class="col-9 supplier_logos"
         [ngClass]="{'supplier_logs_small': articlesService.selectedSearchResultSize === 'small'}">
        <div class="supplierLogo" *ngIf="article?.possibleSuppliers.length <= 5">
            <ng-container *ngFor="let supplier of article?.possibleSuppliers">
                <img *ngIf="supplier !== service.selectedSearchResultSupplier" [supplier]="supplier"
                     [logo-color]="true"/>
            </ng-container>
        </div>
        <ng-template #popContent>
            <div class="supplierPopOver">
                <ng-container *ngFor="let supplier of article?.possibleSuppliers; let i = index">
                    <div class="supplier-container" *ngIf="i >= 4">
                        <img [supplier]="supplier" [logo-color]="true"/>
                    </div>
                </ng-container>
            </div>
        </ng-template>


        <div class="showMoreSupplier" *ngIf="article?.possibleSuppliers.length > 5">
            <ng-container *ngFor="let supplier of article?.possibleSuppliers | slice:0:4; let i = index">
                <img *ngIf="supplier !== service.selectedSearchResultSupplier" [supplier]="supplier"
                     [logo-color]="true"/>
            </ng-container>

            <div class="toolTipDiv" triggers="mouseenter:mouseleave" [ngbPopover]="popContent" container="body">...
            </div>
        </div>
    </div>
</ng-container>


<div class="availability-panel" *ngIf="!article.pseudoArticle">
    <span *ngIf="!service?.availability?.get(article.id)"><i class="fa fa-spinner"
                                                             [ngClass]="{'fa-pulse': !(helperService.browserIsIE() || helperService.browserIsEdge())}"></i></span>
    <ng-container *ngIf="service?.availability?.get(article.id) && service?.availability?.get(article.id).schedule">
        <div *ngFor="let avail of getAvailabilitySchedule(article.id)" [ngClass]="avail.availability"
             style="line-height: 30px;">
            <img class="selectedSupplier img-fluid" [supplier]="service?.availability?.get(article.id).supplier"
                 [logo-color]="service?.availability?.get(article.id)">
            <ng-container>
                            <span class="price"
                                  [ngClass]="{'small': articlesService.selectedSearchResultSize === 'small'}"
                                  *ngIf="service?.availability?.get(article.id).sellInNetPerPiece > 0 && !userService.sale">
                {{ service?.availability?.get(article.id).sellInNetPerPieceFormatted }}<br/>
            </span>
                <span class="price" [ngClass]="{'small': articlesService.selectedSearchResultSize === 'small'}"
                      *ngIf="service?.availability?.get(article.id).sellOutNetPerPiece > 0 && userService.sale">
                {{ service?.availability?.get(article.id).sellOutNetPerPieceFormatted }}<br/>
            </span>
            </ng-container>
            <span class="icon"></span>
        </div>
    </ng-container>
</div>
