import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {ArticlesService} from '../../services/articles.service';
import {CartService} from '../../../cart/services/cart.service';
import {UserService} from '../../../shared/services/user/user.service';
import {HelperService} from '../../../shared/services/helper.service';
import {Subscription} from 'rxjs/Rx';
import {ICartInfoResponse} from '../../../shared/interfaces/DTO/cart/cartInfoResponse';

@Component({
    selector: '[app-articles-row]',
    templateUrl: './articles-row.component.html'
})
export class ArticlesRowComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public article: any;
    @Input() public service: any;
    public imageUrlPrefix: string;
    private subscriptions: Subscription[] = [];
    private cartCount: ICartInfoResponse;

    constructor(
        public articlesService: ArticlesService,
        public cartService: CartService,
        public userService: UserService,
        public helperService: HelperService
    ) {
        this.imageUrlPrefix = environment.baseUrl;
    }

    ngOnInit(): void {
        this.sortPossibleSuppliers();
        this.subscriptions.push(this.cartService.cartCount$.subscribe(cartCount => {
            this.cartCount = cartCount;
            this.checkPosInCart();
        }))
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.article) {
            this.checkPosInCart();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe()
        });

    }

    private checkPosInCart() {
        if (this.cartCount && this.cartCount.positionInfos
            && this.cartCount.positionInfos.length > 0) {
            for (const info of this.cartCount.positionInfos) {
                if (info.articleId === this.article.id) {
                    this.article.quantityInCart = info.quantity;
                    return;
                }
            }
        }
    }

    private sortPossibleSuppliers(): void {
        if (this.userService.suppliers) {
            for (const supplier of this.userService.suppliers) {
                if (this.article.possibleSuppliers.indexOf(supplier.supplier) > -1 &&
                    this.userService.possibleSuppliers.indexOf(supplier) < 0) {
                    this.userService.possibleSuppliers.push(supplier);
                }
            }
        }
    }

    public getAvailabilitySchedule(articleId: number): any[] {
        if (this.service.availability.get(articleId) && this.service.availability.get(articleId).schedule) {
            return this.service.availability.get(articleId).schedule;
        } else {
            return [];
        }
    }
}
