import {Component, OnDestroy, OnInit} from '@angular/core';
import {CompanyAdminService} from "../../services/company-admin.service";
import {Subscription} from "rxjs";
import {Location} from "@angular/common";
import {AssortmentRestService} from "../../../assortment/services/assortment-rest.service";
import {finalize} from "rxjs/operators";
import {HelperService} from "../../../../shared/services/helper.service";
import {IData} from "../../../assortment/interfaces/data";
import {UserService} from "../../../../shared/services/user/user.service";
import {ErrorHandlerService} from "../../../../shared/components/error-handler/services/error-handler.service";
import {AdminService} from "../../../club/services/admin.service";

@Component({
    selector: 'app-supplier-blacklist',
    templateUrl: './supplier-blacklist.component.html',
    styleUrls: ['./supplier-blacklist.component.scss']
})
export class SupplierBlacklistComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    private firmId: number;
    private fromExtern: boolean = false;
    public firmSettings: any;
    public search: string = '';
    public loading: boolean = true;
    public allChecked: boolean = false;
    public dataSuppliers: IData[] = [];
    public reduceList: string[] = [];

    constructor(public companyAdminService: CompanyAdminService,
                private userService: UserService,
                private errorService: ErrorHandlerService,
                public location: Location,
                private helperService: HelperService,
                private adminService: AdminService,
                private dataRestService: AssortmentRestService) {
    }

    ngOnInit(): void {
        this.subscriptions.add(this.companyAdminService.getFirmSettings().subscribe((firmSettings) => {
            this.firmSettings = firmSettings;
            this.reduceList = this.firmSettings.datasupplierBlacklist.possibleValues;
            if (this.reduceList) {
                this.reduceList.sort((a: string, b: string) => {
                    const aIsActive = this.supplierIsSelected(a);
                    const bIsActive = this.supplierIsSelected(b);
                    if (aIsActive === bIsActive) {
                        const aName = this.getSupplierNameById(a);
                        const bName = this.getSupplierNameById(b);

                        return aName.localeCompare(bName);
                    } else {
                        return aIsActive ? -1 : 1;
                    }
                });
            }
        }));


        if (this.location.path().endsWith('supplier-blacklist')) {
            this.companyAdminService.getFirmSettings().subscribe((firmSettings) => {
                this.firmSettings = firmSettings;
            });
            this.fromExtern = false;
        } else {
            this.firmId = parseInt(this.location.path().substring(this.location.path().lastIndexOf('/') + 1));
            this.adminService.getFirmSettings(this.firmId).subscribe((firmSettings) => {
                this.firmSettings = firmSettings;
            });
            this.fromExtern = true;
        }


        this.subscriptions.add(this.dataRestService.getDataSuppliers().pipe(
            finalize(() => {
                this.loading = false;
            })
        ).subscribe({
            error: () => {
                this.helperService.showNotification('TOAST_MESSAGES.ERROR_GET', 'error');
            },
            next: (dataSuppliers: any) => {
                this.dataSuppliers = dataSuppliers;
            }
        }))
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    public getSupplierNameById(id: string): string {
        const supplier = this.dataSuppliers.find(supp => supp.id === parseInt(id, 10));
        return supplier ? supplier.name : '';
    }

    public reduceSuppliers(search: string): any {
        this.search = search;
        search = search.toLocaleLowerCase();

        return this.firmSettings?.datasupplierBlacklist?.possibleValues.filter(id => {
            const name = this.getSupplierNameById(id);
            return name.toLocaleLowerCase().includes(search);
        }).sort((a: string, b: string) => {
            const aIsActive = this.supplierIsSelected(a);
            const bIsActive = this.supplierIsSelected(b);
            if (aIsActive === bIsActive) {
                const aName = this.getSupplierNameById(a);
                const bName = this.getSupplierNameById(b);

                return aName.localeCompare(bName);
            } else {
                return aIsActive ? -1 : 1;
            }
        });
    }

    public supplierIsSelected(supplier: string): boolean {
        const supplierNumber = parseInt(supplier, 10);
        return this.firmSettings.datasupplierBlacklist.value.includes(supplierNumber);
    }

    public changeSupplier(isChecked: boolean, supplier: string): void {
        this.companyAdminService.hasChangesBlacklist = true;
        const supplierNumber = parseInt(supplier, 10);

        if (isChecked) {
            if (!this.firmSettings.datasupplierBlacklist.value.includes(supplierNumber)) {
                this.firmSettings.datasupplierBlacklist.value.push(supplierNumber);
            }
        } else {
            const index = this.firmSettings.datasupplierBlacklist.value.indexOf(supplierNumber);
            if (index > -1) {
                this.firmSettings.datasupplierBlacklist.value.splice(index, 1);
            }
        }

        this.allChecked = this.calculateCheckBoxAllChecked();
    }

    public changeAllSuppliers(isChecked: boolean): void {
        this.companyAdminService.hasChangesBlacklist = true;

        if (isChecked) {
            this.firmSettings.datasupplierBlacklist.value = this.firmSettings.datasupplierBlacklist.possibleValues.map(val => parseInt(val, 10));
        } else {
            this.firmSettings.datasupplierBlacklist.value = [];
        }

        this.allChecked = this.calculateCheckBoxAllChecked();
    }

    public onRowClick(supplier: string) {
        const isChecked = !this.supplierIsSelected(supplier);
        this.changeSupplier(isChecked, supplier);
    }

    private calculateCheckBoxAllChecked(): boolean {
        return this.firmSettings?.datasupplierBlacklist?.possibleValues
            .map((val: string) => parseInt(val, 10))
            .every((val: number) => this.firmSettings.datasupplierBlacklist.value.includes(val));
    }


    public saveChanges() {
        if (this.fromExtern) {
            this.adminService.setFirmSettings(this.firmId, this.firmSettings).subscribe(
                (success) => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                    this.companyAdminService.hasChangesBlacklist = false;
                    this.userService.accountData.settings['cartCreationBehaviour'].value =
                        this.firmSettings['cartCreationBehaviour'].value;
                },
                (error) => {
                    this.errorService.throwError(error.status);
                });
        } else {
            this.companyAdminService.updateSettings(this.firmSettings).subscribe(
                () => {
                    this.helperService.showNotification('TOAST_MESSAGES.SUCCESS_UPDATE', 'success');
                    this.companyAdminService.hasChangesBlacklist = false;
                    this.userService.accountData.settings['cartCreationBehaviour'].value =
                        this.firmSettings['cartCreationBehaviour'].value;
                },
                (error) => {
                    this.errorService.throwError(error.status);
                }
            );
        }
    }
}
