<div class="collapsible-panel card">
    <div class="collapsible-panel-header" (click)="toggleFolded()">
        <div class="icon">
            <i *ngIf="folded" class="fa fa-bold fa-angle-down"></i>
            <i *ngIf="!folded" class="fa fa-bold fa-angle-up"></i>
        </div>
        <div class="text d-flex">
            {{ panelTitle }}
            <svg *ngIf="promotionIconManufacturer" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke-width="1.5" stroke="currentColor" class="ml-2 toolTipDiv" triggers="mouseenter:mouseleave"
                 [ngbPopover]="'SPAREPARTS_VIEW.DETAILS_PANEL.PROMO_DETAILS.MANUFACTURER_PROMO' | translate"
                 container="body">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="m8.99 14.993 6-6m6 3.001c0 1.268-.63 2.39-1.593 3.069a3.746 3.746 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043 3.745 3.745 0 0 1-3.068 1.593c-1.268 0-2.39-.63-3.068-1.593a3.745 3.745 0 0 1-3.296-1.043 3.746 3.746 0 0 1-1.043-3.297 3.746 3.746 0 0 1-1.593-3.068c0-1.268.63-2.39 1.593-3.068a3.746 3.746 0 0 1 1.043-3.297 3.745 3.745 0 0 1 3.296-1.042 3.745 3.745 0 0 1 3.068-1.594c1.268 0 2.39.63 3.068 1.593a3.745 3.745 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.297 3.746 3.746 0 0 1 1.593 3.068ZM9.74 9.743h.008v.007H9.74v-.007Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"/>
            </svg>
            <svg *ngIf="promotionIconSpecialChoice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                 stroke-width="1.5" stroke="currentColor" class="ml-2 toolTipDiv" triggers="mouseenter:mouseleave"
                 [ngbPopover]="'SPAREPARTS_VIEW.DETAILS_PANEL.PROMO_DETAILS.SPECIAL_CHOICE_PROMO' | translate"
                 container="body">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"/>
            </svg>
            <b *ngIf="additionalTitle && additionalTitle != 'Standard'">
                {{ additionalTitle }}
            </b>
        </div>
        <div class="clearfix"></div>
    </div>

    <div class="collapsible-panel-content" [@open]="(folded ? 'true' : 'false')"
         [ngClass]="{ 'without-padding': noPadding }">
        <ng-content></ng-content>
    </div>
</div>
